import { formatEventType } from "../utils/format";
import { currencyFormat, formatNumberWithCommasForHero } from "../utils/helper";
import {
  KalistaContent,
  LuminaContent,
  TauroniusContent,
  SeraphinaContent,
  FloraContent,
  FelsightContent,
  RavosContent,
  FernoContent,
  SilverfangContent,
  ZephyrionContent,
  MarketplaceContent,
  NobleContent,
  DrysiaContent,
  BurnMechanismContent,
  FernixContent,
  LaunchingSoonContent,
  BetaPlayersContent,
  HamstarContent,
  HeroV1Content,
} from "./postContent";

const fernixmain = `${process.env.REACT_APP_S3_BUCKET}/Images/fernixmain-min.png`;
const HeroTwo = `${process.env.REACT_APP_S3_BUCKET}/Images/ahri-min.jpg`;
const HeroThree = `${process.env.REACT_APP_S3_BUCKET}/Images/hero-three-main.png`;
const HeroFour = `${process.env.REACT_APP_S3_BUCKET}/Images/hero-four-main.png`;
const typeIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/fightingIcon.svg`;
const FightTabs = `${process.env.REACT_APP_S3_BUCKET}/Images/Fighttabs.svg`;
const SingleHerosImg = `${process.env.REACT_APP_S3_BUCKET}/Images/videoposter.png`;
const hero5 = `${process.env.REACT_APP_S3_BUCKET}/Images/hero5.png`;
const hero6 = `${process.env.REACT_APP_S3_BUCKET}/Images/hero6.png`;
const hero7 = `${process.env.REACT_APP_S3_BUCKET}/Images/hero7.png`;
const hero8 = `${process.env.REACT_APP_S3_BUCKET}/Images/hero8.png`;
const hero9 = `${process.env.REACT_APP_S3_BUCKET}/Images/hero9.png`;
const hero10 = `${process.env.REACT_APP_S3_BUCKET}/Images/hero110.png`;
const Cyclopis = `${process.env.REACT_APP_S3_BUCKET}/Images/hero-three-main-min.png`;
const FlamelordHero = `${process.env.REACT_APP_S3_BUCKET}/Images/hero-four-main-min.png`;
const Paladis = `${process.env.REACT_APP_S3_BUCKET}/Images/Paladis-min.png`;
const RavenCrow = `${process.env.REACT_APP_S3_BUCKET}/Images/RavenCrow-min.png`;
const VoltClaw = `${process.env.REACT_APP_S3_BUCKET}/Images/aatrox-min.png`;
const IMg = `${process.env.REACT_APP_S3_BUCKET}/Images/Blinke-Strike-poster.png`;
const mail = `${process.env.REACT_APP_S3_BUCKET}/Images/mail.svg`;
const telegram = `${process.env.REACT_APP_S3_BUCKET}/Images/telegram.svg`;
const tw = `${process.env.REACT_APP_S3_BUCKET}/Images/twitterx.svg`;
const yt = `${process.env.REACT_APP_S3_BUCKET}/Images/yt.svg`;
const discord = `${process.env.REACT_APP_S3_BUCKET}/Images/discord.svg`;
const medium = `${process.env.REACT_APP_S3_BUCKET}/Images/medium.svg`;
// const Game = `${process.env.REACT_APP_S3_BUCKET}/Images/Game.svg`;
// const Medal = `${process.env.REACT_APP_S3_BUCKET}/Images/Medal.svg`;
// const Fight = `${process.env.REACT_APP_S3_BUCKET}/Images/Fight.svg`;
const Tauronius = `${process.env.REACT_APP_S3_BUCKET}/Images/tauronius.jpg`;
const LaunchingImg = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/LaunchingImg.png`;
const BetaPlayers = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/BetaPlayersOne.jpeg`;
const hamstarImage = `${process.env.REACT_APP_S3_BUCKET}/Images/harambe.jpg`;
const heroV1 = `${process.env.REACT_APP_S3_BUCKET}/Images/heroesv1.png`;
const Tauronis = `${process.env.REACT_APP_S3_BUCKET}/Images/Tauronius-min.png`;
const Lumina = `${process.env.REACT_APP_S3_BUCKET}/Images/lumina.jpg`;
const NewThree = `${process.env.REACT_APP_S3_BUCKET}/Images/postimg.png`;
const NewFour = `${process.env.REACT_APP_S3_BUCKET}/Images/drysia-news.jpg`;
const Kalista = `${process.env.REACT_APP_S3_BUCKET}/Images/kalista.jpg`;
const Seraphina = `${process.env.REACT_APP_S3_BUCKET}/Images/seraphina.jpg`;
const Flora = `${process.env.REACT_APP_S3_BUCKET}/Images/flora.jpg`;
const Drysiamain = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysiamain-min.png`;
const NewEight = `${process.env.REACT_APP_S3_BUCKET}/Images/noble-news.jpg`;
const Cyclovis = `${process.env.REACT_APP_S3_BUCKET}/Images/cyclovis.jpg`;
const Ravencrow = `${process.env.REACT_APP_S3_BUCKET}/Images/ravencrow.jpg`;
const New11 = `${process.env.REACT_APP_S3_BUCKET}/Images/burnstream-news.jpg`;
const Flamelord = `${process.env.REACT_APP_S3_BUCKET}/Images/flamelord.jpg`;
const Silverclaw = `${process.env.REACT_APP_S3_BUCKET}/Images/silverclaw.jpg`;
const Voltclaw = `${process.env.REACT_APP_S3_BUCKET}/Images/voltclaw.jpg`;
const MarketplaceNews = `${process.env.REACT_APP_S3_BUCKET}/Images/marketplaceNews.jpg`;
const FloraHero = `${process.env.REACT_APP_S3_BUCKET}/Images/flora-hero-min.jpg`;
const KalistaHero = `${process.env.REACT_APP_S3_BUCKET}/Images/Kalista-hero-min.jpg`;
const LuminaHero = `${process.env.REACT_APP_S3_BUCKET}/Images/Lumina-hero-min.jpg`;
const SeraphinaHero = `${process.env.REACT_APP_S3_BUCKET}/Images/seraphina-hero-min.jpg`;
const AboutHeroOne = `${process.env.REACT_APP_S3_BUCKET}/Images/Fernix_client.jpg`;
const AboutHeroTwo = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysia_client.jpg`;
const AboutHeroThree = `${process.env.REACT_APP_S3_BUCKET}/Images/Kali_client.jpg`;
// const WallatIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/wallat_icon.svg`;
// const MapIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/map_icon.svg`;
// const GiftIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/gift_icon.svg`;
const HomeMenu = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/menu/home-menu.svg`;
const DailyMenu = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/menu/daily-menu.svg`;
const WeeklyMenu = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/menu/weekly-menu.svg`;
const ActiveMenu = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/menu/active-menu.svg`;
const CompleteMenu = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/menu/complete-menu.svg`;
const HighMenu = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/menu/high-menu.svg`;
const CombatMenu = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/menu/combat-menu.svg`;
const AboutHeroFour = `${process.env.REACT_APP_S3_BUCKET}/Images/noble_client.jpg`;
const YouTubIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/youtube.svg`;
const FacebookIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/facebook.svg`;
const InstagramIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/instagram.svg`;
const TelegramIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/telegram.svg`;
const TwitterXIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/twitter-x.svg`;
const DLogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
const DHeroIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/hero-icon.svg`;
const DDailyTaskLogo = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
const DWallet = `${process.env.REACT_APP_S3_BUCKET}/Images/wallet.svg`;

const FelsightBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Felsight-bg.png`;
const FelsightCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Felsight-charactor.png`;
const FelsightIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Felsight-Icon-1.png`;
const FelsightIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Felsight-Icon-2.png`;
const FelsightIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Felsight-Icon-3.png`;
const FelsightIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Felsight-Icon-4.png`;

const FernixBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Fernix-bg.png`;
const FernixCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Fernix-charactor.png`;
const FernixIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Fernix-Icon-1.png`;
const FernixIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Fernix-Icon-2.png`;
const FernixIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Fernix-Icon-3.png`;
const FernixIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Fernix-Icon-4.png`;

const DrysiaBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysia.png`;
const DrysiaCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysia-chractor.png`;
const DrysiaIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysia-Icon-1.png`;
const DrysiaIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysia-Icon-2.png`;
const DrysiaIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysia-Icon-3.png`;
const DrysiaIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysia-Icon-4.png`;

const FernoBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Ferno-bg.png`;
const FernoCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Ferno-charactor.png`;
const FernoIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Ferno-Icon-1.png`;
const FernoIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Ferno-Icon-2.png`;
const FernoIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Ferno-Icon-3.png`;
const FernoIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Ferno-Icon-4.png`;

const FloraBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Flora-bg.png`;
const FloraCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Flora-charactor.png`;
const FloraIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Flora-Icon-1.png`;
const FloraIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Flora-Icon-2.png`;
const FloraIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Flora-Icon-3.png`;
const FloraIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Flora-Icon-4.png`;

const KaliBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Kali-bg.png`;
const KaliCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Kali-charactor.png`;
const KaliIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Kali-Icon-1.png`;
const KaliIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Kali-Icon-2.png`;
const KaliIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Kali-Icon-3.png`;
const KaliIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Kali-Icon-4.png`;

const LuminaBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Lumina-bg.png`;
const LuminaCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Lumina-charactor.png`;
const LuminaIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Lumina-Icon-1.png`;
const LuminaIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Lumina-Icon-2.png`;
const LuminaIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Lumina-Icon-3.png`;
const LuminaIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Lumina-Icon-4.png`;

const NobleBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Noble-bg.png`;
const NobleCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Noble-charactor.png`;
const NobleIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Noble-Icon-1.png`;
const NobleIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Noble-Icon-2.png`;
const NobleIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Noble-Icon-3.png`;
const NobleIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Noble-Icon-4.png`;

const RavosBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Ravos-bg.png`;
const RavosCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Ravos-charactor.png`;
const RavosIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Ravos-Icon-1.png`;
const RavosIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Ravos-Icon-2.png`;
const RavosIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Ravos-Icon-3.png`;
const RavosIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Ravos-Icon-4.png`;

const SeraphinaBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Seraphina-bg.png`;
const SeraphinaCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Seraphina-charactor.png`;
const SeraphinaIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Seraphina-Icon-1.png`;
const SeraphinaIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Seraphina-Icon-2.png`;
const SeraphinaIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Seraphina-Icon-3.png`;
const SeraphinaIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Seraphina-Icon-4.png`;

const SilverfangBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Silverfang-bg.png`;
const SilverfangCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Silverfang-charactor.png`;
const SilverfangIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Silverfang-Icon-1.png`;
const SilverfangIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Silverfang-Icon-2.png`;
const SilverfangIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Silverfang-Icon-3.png`;
const SilverfangIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Silverfang-Icon-4.png`;

const TauroniusBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Tauronius-bg.png`;
const TauroniusCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Tauronius-charactor.png`;
const TauroniusIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Tauronius-Icon-1.png`;
const TauroniusIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Tauronius-Icon-2.png`;
const TauroniusIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Tauronius-Icon-3.png`;
const TauroniusIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Tauronius-Icon-4.png`;

const ZephyrionBg = `${process.env.REACT_APP_S3_BUCKET}/Images/Zephyrion-bg.png`;
const ZephyrionCharactor = `${process.env.REACT_APP_S3_BUCKET}/Images/Zephyrion-charactor.png`;
const ZephyrionIcon1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Zephyrion-Icon-1.png`;
const ZephyrionIcon2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Zephyrion-Icon-2.png`;
const ZephyrionIcon3 = `${process.env.REACT_APP_S3_BUCKET}/Images/Zephyrion-Icon-3.png`;
const ZephyrionIcon4 = `${process.env.REACT_APP_S3_BUCKET}/Images/Zephyrion-Icon-4.png`;

const WelcomeBonusIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/welcome-bonus-icon.png`;
const TrackGoldIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/track-your-gold-icon.png`;
const DashboardDesktopIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/dashboard-desktop-icon.png`;
const ReferralRewardsIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/referral-rewards-icon.png`;
const DownloadIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/download-desktop-icon.png`;
const EnjoyPLayIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/enjoy-and-play-icon.png`;

export const featuredHighLights = [
  {
    image: WelcomeBonusIcon,
    title: "Welcome Bonus",
    description: "Earn 100 GOH COINS by creating your account.",
  },
  {
    image: TrackGoldIcon,
    title: "Track Your Gold",
    description:
      "Your total GOH COINS amount appears in the top right corner, reflecting both the gold you earn by playing the game and on the Telegram mini app.",
  },
  {
    image: DashboardDesktopIcon,
    title: "Dashboard",
    description:
      "Go to your dashboard to see your ranking and complete your daily and weekly tasks. You can also perform simple tasks on your PC.",
  },
  {
    image: ReferralRewardsIcon,
    title: "Referral Rewards",
    description:
      "Refer your friends and earn 10% of all the gold they earn from playing the game and using the mini app.",
  },
  {
    image: DownloadIcon,
    title: "Download",
    description:
      "Download the game, log in, and start playing to earn more GOH COINS and enhance your hero's journey.",
  },
  {
    image: EnjoyPLayIcon,
    title: "Enjoy and Play",
    description:
      "Explore, enjoy, and play to maximize your experience and rewards.",
  },
];

export const soicalList = [
  {
    image: telegram,
    title: "Telegram",
    link: "https://t.me/GOHofficial",
  },
  {
    image: tw,
    title: "Twitter",
    link: "https://twitter.com/guildofheroes_",
  },
  {
    image: yt,
    title: "Youtube",
    link: "https://www.youtube.com/@GuildOfHeroes_",
  },
  {
    image: medium,
    title: "Medium",
    link: "https://guildofheroes.medium.com/",
  },
  {
    image: discord,
    title: "Discord",
    link: "https://discord.gg/qr2fUaf2p7",
  },
];

export const navMenu = [
  {
    link: "/",
    title: "Home",
  },
  {
    link: "/about-us",
    title: "ABOUT GOH",
  },
  {
    link: "/heroes",
    title: "Heroes",
  },
  // {
  //   link: "/market-place",
  //   title: "Marketplace",
  // },
  {
    link: "/news",
    title: "News",
  },
  // {
  //   title: "Airdrop",
  //   isPrivate: true,
  //   subMenu: [
  //     {
  //       key: "1",
  //       label: (
  //         <Link
  //           to="/airdrop-highscores"
  //           style={{ color: "#fff", textTransform: "uppercase" }}
  //         >
  //           High Scores
  //         </Link>
  //       ),
  //     },
  //     {
  //       key: "2",
  //       label: (
  //         <Link
  //           to="/airdrop-guide"
  //           style={{ color: "#fff", textTransform: "uppercase" }}
  //         >
  //           Task-Based Airdrop
  //         </Link>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   link: "/contact-us",
  //   title: "Contact",
  // },
  {
    title: "Community",
    subMenu: [
      {
        key: "1",
        label: (
          <a
            target="_blank"
            href="https://discord.gg/qr2fUaf2p7"
            style={{ color: "#fff", textTransform: "uppercase" }}
            rel="noreferrer"
          >
            Discord
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a
            target="_blank"
            href="https://twitter.com/guildofheroes_"
            style={{ color: "#fff", textTransform: "uppercase" }}
            rel="noreferrer"
          >
            Twitter
          </a>
        ),
      },
      {
        key: "3",
        label: (
          <a
            target="_blank"
            href="https://t.me/GOHofficial"
            style={{ color: "#fff", textTransform: "uppercase" }}
            rel="noreferrer"
          >
            Telegram
          </a>
        ),
      },
      {
        key: "4",
        label: (
          <a
            target="_blank"
            href="https://www.youtube.com/@GuildOfHeroes_"
            style={{ color: "#fff", textTransform: "uppercase" }}
            rel="noreferrer"
          >
            Youtube
          </a>
        ),
      },
    ],
  },
  {
    link: "/beta",
    title: "Play Game",
  },
  {
    link: "/dashboard",
    title: "Dashboard",
  },
];

export const Emenrge = [
  {
    title: "Mobile and PC Gaming",
    desc: `Enjoy the strategic depth of Guild of Heroes, seamlessly on both PC and mobile. Engage in thrilling battles, manage your heroes, and experience the game's full potential, no matter your device. Perfect for gamers on the move or at home.`,
  },
  {
    title: "Rewards",
    desc: `Victories in Guild of Heroes earn you GOH GOLD, a valuable asset for trading or staking. Amplify your earnings by using GOH GOLD to stake for additional GOH tokens, fueling your progress and prowess in the game.`,
  },
  {
    title: "Fighting",
    desc: `Claim your victory in the Guild of Heroes by engaging in strategic combat. Outwit and outplay your opponents, leveraging each hero's unique abilities to turn the tide of battle. Forge your path to glory through tactical prowess and collaborative team play.`,
  },
];

export const HeroPanel = [
  {
    image: fernixmain,
    altText: "Fernix",
    maintitle: "Fernix",
    tagline: "The Arcane Protector",
    para: "From the ancient roosts where legends take flight, Fernix emerges, his very essence a testament to the might and majesty of dragons of yore. Clad in scales that shimmer with the wisdom of aeons, he stands as a guardian, a mage whose power resonates with the heartbeats of dragons long passed.",
    headLine: "Skills",
    HeroInnerIcon: [
      {
        image: FernixIcon1,
      },
      {
        image: FernixIcon2,
      },
      {
        image: FernixIcon3,
      },
      {
        image: FernixIcon4,
      },
    ],
    HeroInnerPanel: [
      {
        desc: "Draconis releases a blazing explosion from above, dealing heavy area damage and igniting enemies for additional burning damage.",
      },
      {
        desc: "Unleashes a wave of fire that burns and damages enemies in its path.",
      },
      {
        desc: "Fires a bolt of magical energy that pierces through enemies, dealing substantial damae.",
      },
      {
        desc: "Uses the power of fire to mend wounds and restore health over time.",
      },
    ],
  },
  {
    image: Drysiamain,
    altText: "Drysia ",
    maintitle: "Drysia ",
    tagline: "The Eclipse Warlord",
    para: "In the heart of the shadowed groves of Arcanterra, Drysia stands as the chieftain of whispers and dusk. Clad in the might of the night and wielding twin obsidian axes, she commands the battlefield with the authority of darkness itself. Her presence is a foreboding omen, her power an echo of the void that swallows light.",
    headLine: "Skills",
    HeroInnerIcon: [
      {
        image: DrysiaIcon1,
      },
      {
        image: DrysiaIcon2,
      },
      {
        image: DrysiaIcon3,
      },
      {
        image: DrysiaIcon4,
      },
    ],
    HeroInnerPanel: [
      {
        desc: "Unleashes a flurry of sharp spikes that pierce enemies, causing damage over time.",
      },
      {
        desc: "Strikes from above with a powerful impact, dealing with a powerful impact, dealing heavy damage and creating a shockwave that disrupts enemies.",
      },
      {
        desc: "Fires a bolt of dark energy that drains life from enemies, weakening them.",
      },
      {
        desc: "Uses dark magic to heal herself over time, restoring her strength.",
      },
    ],
  },
  {
    image: HeroThree,
    altText: "Cyclopis",
    maintitle: "Felsight",
    tagline: "The Mountain's Gaze",
    para: "From the secluded peaks where ancient echoes resonate, Felsight the Unblinking takes his solemn stand in 'Guild of Heroes.' This solitary cyclops, hewn from the very heart of the ancient mountains, is a sentinel of stone and strength. With an eye that pierces through chaos and a focus sharp as the edge of dawn, he surveys the battlefield with an anticipation that belies his imposing form.",
    headLine: "Skills",
    HeroInnerIcon: [
      {
        image: FelsightIcon1,
      },
      {
        image: FelsightIcon2,
      },
      {
        image: FelsightIcon3,
      },
      {
        image: FelsightIcon4,
      },
    ],
    HeroInnerPanel: [
      {
        desc: "Fires a concentrated energy beam from his eye, piercing through multiple enemies",
      },
      {
        desc: "Strikes the ground to create a shockwave that knocks back and stuns enemies in the area",
      },
      {
        desc: "Hurls a large rock infused with magical energy, crushing foes upon impact.",
      },
      {
        desc: "Temporarily hardens his skin, reducing damage taken and gradually healing wounds.",
      },
    ],
  },
  {
    image: HeroFour,
    altText: "Ferno",
    maintitle: "Ferno",
    tagline: "Elemental Might Unleashed",
    para: " From the molten depths of Guild of Heroes comes Ferno, a titanic force of nature whose fiery heart burns with the intensity of a thousand suns. This colossal elemental entity wields the raw power of fire and earth, scorching enemies with his fiery roar and leaving devastation in his wake with every thunderous step.",
    headLine: "Skills",
    HeroInnerIcon: [
      {
        image: FernoIcon1,
      },
      {
        image: FernoIcon2,
      },
      {
        image: FernoIcon3,
      },
      {
        image: FernoIcon4,
      },
    ],
    HeroInnerPanel: [
      {
        desc: "Releases a fiery roar that creates a burning zone around enemies, damaging and repelling foes.",
      },
      {
        desc: "Fires a molten rock that burns and slows enemies, dealing damage over time.",
      },
      {
        desc: "Hurls a flaming projectile that explodes on impact, causing area damage and igniting enemies.",
      },
      {
        desc: "Absorbs heat from the surroundings to restore health, replenishing his vitality in the midst of battle.",
      },
    ],
  },
];
export const AboutHeroPanel = [
  {
    image: AboutHeroThree,
    altText: "Volt Claw",
    maintitle: "Volt Claw",
    tagline: "The Warrior Prince",
    para: "Praesent quis metus eget ante accumsan porta ac ac ante. In ut faucibus urna, quis feugiat augue. Nunc sit amet est tincidunt, dictum felis at, rutrum ipsum. Sed vel posuere mi. Etiam sed feugiat quam. Maecenas malesuada est eget mauris interdum.",
  },
  {
    image: AboutHeroOne,
    altText: "Volt Claw",
    maintitle: "Volt Claw",
    tagline: "The Warrior Prince",
    para: "Praesent quis metus eget ante accumsan porta ac ac ante. In ut faucibus urna, quis feugiat augue. Nunc sit amet est tincidunt, dictum felis at, rutrum ipsum. Sed vel posuere mi. Etiam sed feugiat quam. Maecenas malesuada est eget mauris interdum.",
  },
  {
    image: AboutHeroTwo,
    altText: "Volt Claw",
    maintitle: "Volt Claw",
    tagline: "The Warrior Prince",
    para: "Praesent quis metus eget ante accumsan porta ac ac ante. In ut faucibus urna, quis feugiat augue. Nunc sit amet est tincidunt, dictum felis at, rutrum ipsum. Sed vel posuere mi. Etiam sed feugiat quam. Maecenas malesuada est eget mauris interdum.",
  },
  {
    image: AboutHeroFour,
    altText: "Volt Claw",
    maintitle: "Volt Claw",
    tagline: "The Warrior Prince",
    para: "Praesent quis metus eget ante accumsan porta ac ac ante. In ut faucibus urna, quis feugiat augue. Nunc sit amet est tincidunt, dictum felis at, rutrum ipsum. Sed vel posuere mi. Etiam sed feugiat quam. Maecenas malesuada est eget mauris interdum.",
  },
];

export const HerosBannerContent = {
  title: "CHOOSE YOUR HERO",
  desc: `Choose from 13 heroes and a variety of upgraded skins for each hero. We’re also launching 30 more heroes soon, so stay tuned for even more options!
  `,
};
export const PostBanner = {
  title: "Game updates",
};

export const SingleProductCard = [
  {
    image: Drysiamain,
    title: "Drysia",
    subtitle: "The Eclipse Warlord",
    description:
      "In the heart of the shadowed groves of Arcanterra, Drysia stands as the chieftain of whispers and dusk. Clad in the might of the night and wielding twin obsidian axes, she commands the battlefield with the authority of darkness itself. Her presence is a foreboding omen, her power an echo of the void that swallows light.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: DrysiaCharactor,
    bgImage: DrysiaBg,
    SingleHeroIcons: [
      {
        image: DrysiaIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/Q.mp4",
          title: "Spiked Barrage",
          description:
            "Unleashes a flurry of sharp spikes that pierce enemies, causing damage over time.",
        },
      },
      {
        image: DrysiaIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/W.mp4",
          title: "Celestial Drop",
          description:
            "Strikes from above with a powerful impact, dealing with a powerful impact, dealing heavy damage and creating a shockwave that disrupts enemies.",
        },
      },
      {
        image: DrysiaIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/E.mp4",
          title: "Dark Bolt",
          description:
            "Fires a bolt of dark energy that drains life from enemies, weakening them.",
        },
      },
      {
        image: DrysiaIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/R.mp4",
          title: "Shadow Mend",
          description:
            "uses dark magic to heal herself over time, restoring her strength.",
        },
      },
    ],
  },
  {
    image: Cyclopis,
    charactorImage: FelsightCharactor,
    bgImage: FelsightBg,
    title: "Felsight",
    subtitle: "The Mountain's Gaze",
    description:
      "From the secluded peaks where ancient echoes resonate, Felsight the Unblinking takes his solemn stand in 'Guild of Heroes.' This solitary cyclops, hewn from the very heart of the ancient mountains, is a sentinel of stone and strength. With an eye that pierces through chaos and a focus sharp as the edge of dawn, he surveys the battlefield with an anticipation that belies his imposing form.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleHeroIcons: [
      {
        image: FelsightIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Felsight/Q.mp4",
          title: "Cyclops Beam",
          description:
            "Fires a concentrated energy beam from his eye, piercing through multiple enemies.",
        },
      },
      {
        image: FelsightIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Felsight/W.mp4",
          title: "Ground Smash",
          description:
            "Strikes the ground to create a shockwave that knocks back and stuns enemies in the area.",
        },
      },
      {
        image: FelsightIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Felsight/E.mp4",
          title: "Rock Toss",
          description:
            "Hurls a large rock infused with magical energy, crushing foes upon impact.",
        },
      },
      {
        image: FelsightIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Felsight/R.mp4",
          title: "Stone Skin",
          description:
            "Temporarily hardens his skin, reducing damage taken and gradually healing wounds.",
        },
      },
    ],
  },
  {
    image: fernixmain,
    title: "Fernix",
    subtitle: "The Arcane Protector",
    description:
      "From the ancient roosts where legends take flight, Fernix emerges, his very essence a testament to the might and majesty of dragons of yore. Clad in scales that shimmer with the wisdom of aeons, he stands as a guardian, a mage whose power resonates with the heartbeats of dragons long passed.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: FernixCharactor,
    bgImage: FernixBg,
    SingleHeroIcons: [
      {
        image: FernixIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/Q.mp4",
          title: "Inferno Burst",
          description:
            "Draconis releases a blazing explosion from above, dealing heavy area damage and igniting enemies for additional burning damage.",
        },
      },
      {
        image: FernixIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/W.mp4",
          title: "Flame Wave",
          description:
            "Unleashes a wave of fire that burns and damages enemies in its path.",
        },
      },
      {
        image: FernixIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/E.mp4",
          title: "Arcane Bolt",
          description:
            "Fires a bolt of magical energy that pierces through enemies, dealing substantial damae.",
        },
      },

      {
        image: FernixIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/R.mp4",
          title: "Healing Flame",
          description:
            "Uses the power of fire to mend wounds and restore health over time.",
        },
      },
    ],
  },
  {
    image: FlamelordHero,
    title: "Ferno",
    subtitle: "Elemental Might Unleashed",
    description:
      "From the molten depths of Guild of Heroes comes Ferno, a titanic force of nature whose fiery heart burns with the intensity of a thousand suns. This colossal elemental entity wields the raw power of fire and earth, scorching enemies with his fiery roar and leaving devastation in his wake with every thunderous step.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: FernoCharactor,
    bgImage: FernoBg,
    SingleHeroIcons: [
      {
        image: FernoIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/Q.mp4",
          title: "Flame Roar",
          description:
            "Releases a fiery roar that creates a burning zone around enemies, damaging and repelling foes.",
        },
      },
      {
        image: FernoIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/W.mp4",
          title: "Magma Shot",
          description:
            "Fires a molten rock that burns and slows enemies, dealing damage over time.",
        },
      },
      {
        image: FernoIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/E.mp4",
          title: "Fireball",
          description:
            "Hurls a flaming projectile that explodes on impact, causing area damage and igniting enemies.",
        },
      },
      {
        image: FernoIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/R.mp4",
          title: "Inferno Recovery",
          description:
            "Absorbs heat from the surroundings to restore health, replenishing his vitality in the midst of battle.",
        },
      },
    ],
  },
  {
    image: FloraHero,
    title: "Flora",
    subtitle: "The Sylvan Enchantress",
    description:
      "In every whisper of the wind and rustle of leaves, Flora, the enchanting dryad of 'Guild of Heroes,' moves with the grace of the living forest. She is the guardian of growth, the weaver of woodlands, channeling the ancient power of nature to both nurture and neutralize.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: FloraCharactor,
    bgImage: FloraBg,
    SingleHeroIcons: [
      {
        image: FloraIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/Q.mp4",
          title: "Nature’s Embrace",
          description:
            "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
        },
      },
      {
        image: FloraIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/W.mp4",
          title: "Nature’s Embrace",
          description:
            "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
        },
      },

      {
        image: FloraIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/E.mp4",
          title: "Nature’s Embrace",
          description:
            "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
        },
      },
      {
        image: FloraIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/R.mp4",
          title: "Nature’s Embrace",
          description:
            "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
        },
      },
    ],
  },
  {
    image: KalistaHero,
    title: "Kali",
    subtitle: "The Sanguimancer Sovereign",
    description:
      "In the hidden chambers of Arcanterra's forgotten crypts, Kalista, the Enthroned Sanguimancer, reigns with an aura of dread and dominion. She is the arbiter of life and death, weaving the essence of vitality into a tapestry of control and command. Her mastery over the sanguine arts is unrivaled, as she bends the will of friends and foes alike with a mere gesture of her enchanted wand.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: KaliCharactor,
    bgImage: KaliBg,
    SingleHeroIcons: [
      {
        image: KaliIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/Q.mp4",
          title: "Poison Bolt",
          description: "Fires a magical bolt that drains enemy health.",
        },
      },
      {
        image: KaliIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/W.mp4",
          title: "Ethereal Strike",
          description:
            "Delivers a piercing blow that deals damage and weakens enemies.",
        },
      },
      {
        image: KaliIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/E.mp4",
          title: "Dark Orb",
          description:
            "Sends a shadow orb that explodes on contact, causing area damage.",
        },
      },
      {
        image: KaliIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/R.mp4",
          title: "Lifeblood Restoration",
          description:
            "Uses absorbed life force to heal herself, replenishing her vitality.",
        },
      },
    ],
  },
  {
    image: LuminaHero,
    title: "Lumina",
    subtitle: "The Celestial Marksman",
    description:
      "In the quiet before dawn and the hush of dusk, Lumina, the Luminous Archer, takes her stance. She is the embodiment of the sky's dual nature, where the sun's clarity meets the moon's mystery. With a bow that sings the song of the heavens, Lumina strikes with a grace that is as radiant as it is deadly.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: LuminaCharactor,
    bgImage: LuminaBg,
    SingleHeroIcons: [
      {
        image: LuminaIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/Q.mp4",
          title: "Arrow Shot",
          description:
            "Fires a precise arrow at her target, dealing high damage.",
        },
      },
      {
        image: LuminaIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/W.mp4",
          title: "Frost Arrow",
          description:
            "Lumina fires an enchanted ice arrow that deals damage to enemies and inflicts a frostbite effect.",
        },
      },

      {
        image: LuminaIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/E.mp4",
          title: "Mystic Arrow",
          description:
            "Launches an arrow that explodes on impact, causing area damage.",
        },
      },
      {
        image: LuminaIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/R.mp4",
          title: "healing Light",
          description:
            "Channels a beam of light to restore her health and that of nearby allies, enhancing their durability.",
        },
      },
    ],
  },
  {
    image: Paladis,
    title: "Noble",
    subtitle: "The Time-Honored Sentinel",
    description:
      "In the verdant fields and over the storied cobblestones of Arcanterra strides Noble, the weathered knight whose very name evokes tales of valor and perseverance. With the wisdom of countless battles etched into his being, Noble wields his axe not merely as a weapon, but as an extension of a lifetime dedicated to the mastery of martial prowess.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: NobleCharactor,
    bgImage: NobleBg,
    SingleHeroIcons: [
      {
        image: NobleIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/Q.mp4",
          title: "Blazing Arc",
          description:
            "Sends a fiery arc of energy with a swing of his axe, burning and slashing foes.",
        },
      },
      {
        image: NobleIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/W.mp4",
          title: "Arcane Slash",
          description:
            "Strikes with a magical sword, dealing damage and releasing a burst of magical energy that affects enemies in its path.",
        },
      },

      {
        image: NobleIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/E.mp4",
          title: "Mystic Axe Throw",
          description:
            "Hurls a magical axe projectile that deals damage and explodes on impact, spreading arcane energy to nearby enemies.",
        },
      },
      {
        image: NobleIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/R.mp4",
          title: "Battle Mend",
          description:
            "Uses battle energy to heal his wounds over time, restoring his strength during combat.",
        },
      },
    ],
  },
  {
    image: RavenCrow,
    title: "Ravos",
    subtitle: "The Ethereal Warrior",
    description:
      "From the shadowed realms of Arcanterra, emerges Ravos, a warrior bound to the spirits of the air. With wings as dark as the raven and eyes glowing with the foresight of the crow, he stands as a sentinel between the worlds. Ravos wields his spectral axe with otherworldly precision, his strikes a blend of the ethereal and the lethal, as he dominates the skies and battlefields.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: RavosCharactor,
    bgImage: RavosBg,
    SingleHeroIcons: [
      {
        image: RavosIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/Q.mp4",
          title: "Raven’s Talon",
          description:
            "Hurls a dark energy projectile that pierces through enemies, dealing damage, and leaving a shadowy mark that weaken.",
        },
      },

      {
        image: RavosIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/W.mp4",
          title: "Raven’s Descent",
          description:
            "The character swoops from above with a powerful strike, dealing heavy damage and creating a dark shockwave upon impact.",
        },
      },
      {
        image: RavosIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/E.mp4",
          title: "Bone Spear",
          description:
            "Launches a sharpened bone projectile that pierces and immobilizes foes.",
        },
      },
      {
        image: RavosIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/R.mp4",
          title: "Necrotic Regrowth",
          description:
            "Uses dark magic to mend bones and flesh, restoring health over time.",
        },
      },
    ],
  },
  {
    image: SeraphinaHero,
    title: "Seraphina",
    subtitle: "The Shadow's Sting",
    description:
      "In the quietest whispers of twilight, Seraphina, the Serpent Assassin of 'Guild of Heroes,' emerges. She is the unseen danger, the silent blade in the dark, whose very presence is as enigmatic as it is lethal. With movements that mirror the serpent's sinuous grace, she strikes with a cold precision known only to the shadows.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: SeraphinaCharactor,
    bgImage: SeraphinaBg,
    SingleHeroIcons: [
      {
        image: SeraphinaIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/Q.mp4",
          title: "Venom Dart",
          description:
            "Fires a poisoned dart that deals damage over time, weakening enemies.",
        },
      },
      {
        image: SeraphinaIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/W.mp4",
          title: "Seraphina’s Eclipse",
          description:
            "Strikes from above to create a circle of dark magic, damaging and weakening enemies within its radius.",
        },
      },
      {
        image: SeraphinaIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/E.mp4",
          title: "Void Exhale",
          description:
            "Seraphina inhales deeply, gathering dark energy, and then exhales is as a projectile that damages enemies and reduces their defenses.",
        },
      },
      {
        image: SeraphinaIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/R.mp4",
          title: "Serpent’s Renewal",
          description:
            "Sheds her skin to heal injuries and remove negative effects, restoring health.",
        },
      },
    ],
  },
  {
    image: HeroTwo,
    title: "Silverfang",
    subtitle: "Predator of the Shadows",
    description:
      "Silverfang strikes not just with his limbs but with fear itself. As a beast woven from the nightmares of his enemies, his presence on the battlefield is as chilling as it is deadly. His fearsome howl is the prelude to a symphony of destruction, as his silver-coated claws catch the moonlight before they strike.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: SilverfangCharactor,
    bgImage: SilverfangBg,
    SingleHeroIcons: [
      {
        image: SilverfangIcon1,
        content: {
          video:
            "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/Q.mp4",
          title: "Ferocious Swipe",
          description:
            "Sends a wave of energy with a powerful swipe, knocking back and wounding foes in its path.",
        },
      },
      {
        image: SilverfangIcon2,
        content: {
          video:
            "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/W.mp4",
          title: "Lunar Claw",
          description:
            "The werewolf channels lunar energy into a powerful strike and leaving a glowing lunar mark that causes additional harm over time.",
        },
      },
      {
        image: SilverfangIcon3,
        content: {
          video:
            "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/E.mp4",
          title: "Howling Roar",
          description:
            "Emits a fearsome roar that disorient and damages enemies, lowering their defenses and making them easier to defeat.",
        },
      },
      {
        image: SilverfangIcon4,
        content: {
          video:
            "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/E.mp4",
          title: "Lunar Regeneration",
          description:
            "Harness moonlight energy to gradually heal wounds, restoring health over time.",
        },
      },
    ],
  },
  {
    image: Tauronis,
    title: "Tauronius",
    subtitle: "The Horned Vanguard",
    description:
      "In the wild expanses of Arcanterra where nature's call is law, Taurosius, with his twin enchanted axes, reigns supreme. His presence is a force of nature, a testament to the untamed spirit that thrives within the heart of the wilderness. Taurosius wields the power of the earth and wind, a vanguard whose every move resonates with Satyric Harmony.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: TauroniusCharactor,
    bgImage: TauroniusBg,
    SingleHeroIcons: [
      {
        image: TauroniusIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/Q.mp4",
          title: "Celestial Beam",
          description: "Sends forth a magic projectile that deals damage.",
        },
      },
      {
        image: TauroniusIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/W.mp4",
          title: "Earthshaker Stomps",
          description:
            "Stomps the ground to create a shockwave that damages and stuns enemies.",
        },
      },
      {
        image: TauroniusIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/E.mp4",
          title: "Nature’s Wrath",
          description:
            "Launches a burst of magical energy from his axes, striking multiple foes.",
        },
      },

      {
        image: TauroniusIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/R.mp4",
          title: "Forest’s Embrace",
          description:
            "Channels the power of the forest to heal his wounds over time, restoring vitality.",
        },
      },
    ],
  },
  {
    image: VoltClaw,
    title: "Zephyrion",
    subtitle: "The Storm’s Fury",
    description:
      "Amidst the heroes of Guild of Heroes, Zephyrion stands as a beacon of raw power and electrifying presence. His tragic past fuels his relentless pursuit for justice, channeling the very force of electricity to stun and subdue his adversaries.",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleImg: SingleHerosImg,
    charactorImage: ZephyrionCharactor,
    bgImage: ZephyrionBg,
    SingleHeroIcons: [
      {
        image: ZephyrionIcon1,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/Q.mp4",
          title: "Electric Spikes",
          description:
            "Fires electrified spikes from his forearm, piercing enemies and delivering a powerful electric shock wave.",
        },
      },
      {
        image: ZephyrionIcon2,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/W.mp4",
          title: "Meteor Strike",
          description:
            "Calls down a massive asteroid that crashes into the ground, creating a powerful shockwave that deals heavy damage and disrupts enemies in the area.",
        },
      },

      {
        image: ZephyrionIcon3,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/E.mp4",
          title: "Shock Fist",
          description:
            "Launches an electric punch that travels through the air, stunning and damaging enemies upon impact.",
        },
      },
      {
        image: ZephyrionIcon4,
        content: {
          video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/R.mp4",
          title: "Electro Charge",
          description:
            "Absorbs ambient electricity to restore health over time, enhancing his vitality and resilience.",
        },
      },
    ],
  },
];

export const Assassuns = [
  {
    image: hero5,
    title: "Fiz",
    subtitle: "The Dark Knight",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleHeroIcons: [
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
    ],
    SingleImg: SingleHerosImg,
  },
  {
    image: hero6,
    title: "Jinx",
    subtitle: "The Dark Knight",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleHeroIcons: [
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          subline: "Passive",
          title: "Deathbringer Stance",
          desc: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Aenean accumsan mauris ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
    ],
    SingleImg: SingleHerosImg,
  },
  {
    image: hero7,
    title: "Kled",
    subtitle: "The Dark Knight",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleHeroIcons: [
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
    ],
    SingleImg: SingleHerosImg,
  },
  {
    image: hero8,
    title: "Morgan",
    subtitle: "The Dark Knight",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleHeroIcons: [
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
    ],
    SingleImg: SingleHerosImg,
  },
  {
    image: hero9,
    title: "Nami",
    subtitle: "The Dark Knight",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleHeroIcons: [
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
    ],
    SingleImg: SingleHerosImg,
  },
  {
    image: hero10,
    title: "Poppy",
    subtitle: "The Dark Knight",
    type: "Fighter",
    typeIcon: typeIcon,
    difficulity: "Moderate",
    abilities: "Abilities",
    SingleHeroIcons: [
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
      {
        image: FightTabs,
        content: {
          className: "PlayReverse",
          imageinner: IMg,
          altText: "Hero",
          subline: "Passive",
          tagline: "Deathbringer Stance ",
          para: `Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          descTwo: `Interdum et malesuada fames ac ante ipsum primis in faucibus.`,
        },
      },
    ],
    SingleImg: SingleHerosImg,
  },
];

export const NewPost = [
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/tauronius.jpg`,
    link: "https://guildofheroes.medium.com/tauronius-b9c2e79bff2d",
    title: "Meet the Hero: Tauronius",
    desc: `In the wild expanses of Arcanterra where nature’s call is law, Taurosius, with his twin enchanted axes, reigns supreme. His presence is a force of nature, a testament to the untamed spirit that thrives within the heart of the wilderness. Taurosius wields the power of the earth and wind, a vanguard whose every move resonates with Satyric Harmony.​`,
  },
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/lumina.jpg`,
    link: "https://guildofheroes.medium.com/lumina-d554c86991f3",
    title: "Meet the Hero: Lumina",
    desc: `In the quiet before dawn and the hush of dusk, Lumina, the Luminous Archer, takes her stance. She is the embodiment of the sky’s dual nature, where the sun’s clarity meets the moon’s mystery. With a bow that sings the song of the heavens, Lumina strikes with a grace that is as radiant as it is deadly.`,
  },

  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/kalista.jpg`,
    link: "https://guildofheroes.medium.com/kalista-c7bef5637481",
    title: "Meet the Hero: Kali",
    desc: `In the hidden chambers of Arcanterra’s forgotten crypts, Kali, the Enthroned Sanguimancer, reigns with an aura of dread and dominion. She is the arbiter of life and death, weaving the essence of vitality into a tapestry of control and command. Her mastery over the sanguine arts is unrivaled, as she bends the will of friends and foes alike with a mere gesture of her enchanted wand.​`,
  },
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/seraphina.jpg`,
    link: "https://guildofheroes.medium.com/seraphina-140d4ef523e7",
    title: "Meet the Hero: Seraphina",
    desc: `In the quietest whispers of twilight, Seraphina, the Serpent Assassin of “Guild of Heroes,” emerges. She is the unseen danger, the silent blade in the dark, whose very presence is as enigmatic as it is lethal. With movements that mirror the serpent’s sinuous grace, she strikes with a cold precision known only to the shadows.`,
  },

  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/flora.jpg`,
    link: "https://guildofheroes.medium.com/flora-31ad32c0f956",
    title: "Meet the Hero: Flora",
    desc: `In every whisper of the wind and rustle of leaves, Flora, the enchanting dryad of “Guild of Heroes,” moves with the grace of the living forest. She is the guardian of growth, the weaver of woodlands, channeling the ancient power of nature to both nurture and neutralize.`,
  },

  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/cyclovis.jpg`,
    link: "https://guildofheroes.medium.com/cyclovis-cd663cf147b8",
    title: "Meet the Hero: Felsight",
    desc: `From the secluded peaks where ancient echoes resonate, Felsight the Unblinking takes his solemn stand in “Guild of Heroes.” This solitary cyclops, hewn from the very heart of the ancient mountains, is a sentinel of stone and strength. With an eye that pierces through chaos and a focus sharp as the edge of dawn, he surveys the battlefield with an anticipation that belies his imposing form.​`,
  },

  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/ravencrow.jpg`,
    link: "https://guildofheroes.medium.com/ravencrow-b0dd53a058cb",
    title: "Meet the Hero: Ravos",
    desc: `From the shadowed realms of Arcanterra, emerges Ravencrow, a warrior bound to the spirits of the air. With wings as dark as the raven and eyes glowing with the foresight of the crow, he stands as a sentinel between the worlds. Ravencrow wields his spectral axe with otherworldly precision, his strikes a blend of the ethereal and the lethal, as he dominates the skies and battlefields alike.​`,
  },
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/flamelord.jpg`,
    link: "https://guildofheroes.medium.com/meet-the-hero-flame-lord-daaac6f01031",
    title: "Meet the Hero: Ferno",
    desc: `From the molten depths of Guild of Heroes comes Ferno, a titanic force of nature whose fiery heart burns with the intensity of a thousand suns. This colossal elemental entity wields the raw power of fire and earth, scorching enemies with his fiery roar and leaving devastation in his wake with every thunderous step.​`,
  },

  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/silverclaw.jpg`,
    link: "https://guildofheroes.medium.com/meet-the-hero-silverclaw-09e738b66fdd",
    title: "Meet the Hero: Silverfang",
    desc: `Silverfang strikes not just with his limbs but with fear itself. As a beast woven from the nightmares of his enemies, his presence on the battlefield is as chilling as it is deadly. His fearsome howl is the prelude to a symphony of destruction, as his silver-coated claws catch the moonlight before they strike.​`,
  },
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/voltclaw.jpg`,
    link: "https://guildofheroes.medium.com/meet-the-hero-voltclaw-4118cf736204",
    title: "Meet the Hero: Zephyrion",
    desc: `Amidst the heroes of Guild of Heroes, Voltclaw stands as a beacon of raw power and electrifying presence. His tragic past fuels his relentless pursuit for justice, channeling the very force of electricity to stun and subdue his adversaries.​`,
  },
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/marketplaceNews.jpg`,
    link: "https://guildofheroes.medium.com/guild-of-heroes-marketplace-explained-863ac92e11f1",
    title: "Guild of Heroes Marketplace Explained",
    desc: `The in-game marketplace of Guild of Heroes offers a dynamic and enriching aspect to the gaming experience. Serving as a central feature, it provides players an avenue to augment their gameplay through a diverse range of upgrades and customization options.​`,
  },

  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/noble-news.jpg`,
    link: "https://guildofheroes.medium.com/paladis-40780fb47f88",
    title: "Meet the Hero: Noble",
    desc: `In the verdant fields and over the storied cobblestones of Arcanterra strides Noble, the weathered knight whose very name evokes tales of valor and perseverance. With the wisdom of countless battles etched into his being, Noble wields his axe not merely as a weapon, but as an extension of a lifetime dedicated to the mastery of martial prowess.`,
  },
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/drysia-news.jpg`,
    link: "https://guildofheroes.medium.com/drysia-e9dc501cce19",
    title: "Meet the Hero: Drysia",
    desc: `In the heart of the shadowed groves of Arcanterra, Drysia stands as the chieftain of whispers and dusk. Clad in the might of the night and wielding twin obsidian axes, she commands the battlefield with the authority of darkness itself. Her presence is a foreboding omen, her power an echo of the void that swallows light.`,
  },
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/burnstream-news.jpg`,
    link: "https://guildofheroes.medium.com/guild-of-heroes-burnstream-mechanics-00c4c9e6b87b",
    title: "Guild of Heroes Burnstream Mechanics",
    desc: `In Guild of Heroes, the burn mechanism operates through a dual-pathed approach that ensures both the game’s economy and its players benefit from the process.​`,
  },
  {
    PostImg: `${process.env.REACT_APP_S3_BUCKET}/Images/postimg.png`,
    link: "https://guildofheroes.medium.com/draconis-223657828851",
    title: "Meet the Hero: Fernix",
    desc: `From the ancient roosts where legends take flight, Fernix emerges, his very essence a testament to the might and majesty of dragons of yore. Clad in scales that shimmer with the wisdom of aeons, he stands as a guardian, a mage whose power resonates with the heartbeats of dragons long passed.`,
  },
];

export const General = [
  {
    id: "guild_of_heroes_partners_with_harambe",
    PostImg: hamstarImage,
    link: "",
    title: "Guild of Heroes Partners with Harambe",
    desc: `We’re excited to announce a major collaboration with Harambe, the #1 ranked worldwide Wukong player in League of Legends. With a massive audience of dedicated fans and a proven track record of exceptional gameplay, Harambe brings unparalleled skill and influence to the Guild of Heroes community.​`,
    createdAt: "September 11, 2024",
    content: HamstarContent,
  },
  {
    id: "guild_of_heroes_v1_31_development_update",
    PostImg: heroV1,
    link: "",
    title: "Guild of Heroes v1.31 Development Update",
    desc: `We’re excited to bring you the latest updates on Guild of Heroes as we continue making strides toward our public beta release. Our development team has been hard at work, and we want to share some of the progress we've made in this update.​`,
    createdAt: "September 6, 2024",
    content: HeroV1Content,
  },
  {
    id: "patch_v1_21_updates_for_closed_beta_players",
    PostImg: BetaPlayers,
    link: "https://x.com/GuildOfHeroes_/status/1828728279743779263",
    title: "Patch v1.21 Updates for Closed Beta Players",
    desc: `We’re excited to bring you Patch v1.21 for Guild of Heroes, packed with new content and improvements as we prepare for the next phase of our game’s development. This patch is available exclusively for our closed beta players, with a public beta launch just around the corner!​`,
    createdAt: "September 1, 2024",
    content: BetaPlayersContent,
  },
  {
    id: "tap_to_earn_game_launching_soon",
    PostImg: LaunchingImg,
    link: "",
    title: "Tap to Earn Game Launching Soon",
    desc: `Exciting news for all Guild of Heroes fans! In just a few weeks, we are thrilled to announce the launch of our highly anticipated Tap to Earn game. This innovative addition will allow players to earn GOH coins by performing various tasks directly within our Telegram mini-app.​`,
    createdAt: "August 28, 2024",
    content: LaunchingSoonContent,
  },
  {
    id: "meet_the_hero_tauronius",
    PostImg: Tauronius,
    link: "https://guildofheroes.medium.com/tauronius-b9c2e79bff2d",
    title: "Meet the Hero: Tauronius",
    desc: `In the wild expanses of Arcanterra where nature’s call is law, Taurosius, with his twin enchanted axes, reigns supreme. His presence is a force of nature, a testament to the untamed spirit that thrives within the heart of the wilderness. Taurosius wields the power of the earth and wind, a vanguard whose every move resonates with Satyric Harmony.​`,
    createdAt: "Jan 11, 2024",
    content: TauroniusContent,
  },
  {
    id: "meet_the_hero_lumina",
    PostImg: Lumina,
    link: "https://guildofheroes.medium.com/lumina-d554c86991f3",
    title: "Meet the Hero: Lumina",
    desc: `In the quiet before dawn and the hush of dusk, Lumina, the Luminous Archer, takes her stance. She is the embodiment of the sky’s dual nature, where the sun’s clarity meets the moon’s mystery. With a bow that sings the song of the heavens, Lumina strikes with a grace that is as radiant as it is deadly.`,
    createdAt: "Jan 11, 2024",
    content: LuminaContent,
  },
  {
    id: "meet_the_hero_kali",
    PostImg: Kalista,
    link: "https://guildofheroes.medium.com/kalista-c7bef5637481",
    title: "Meet the Hero: Kali",
    desc: `In the hidden chambers of Arcanterra’s forgotten crypts, Kali, the Enthroned Sanguimancer, reigns with an aura of dread and dominion. She is the arbiter of life and death, weaving the essence of vitality into a tapestry of control and command. Her mastery over the sanguine arts is unrivaled, as she bends the will of friends and foes alike with a mere gesture of her enchanted wand.​`,
    createdAt: "Jan 11, 2024",
    content: KalistaContent,
  },
  {
    id: "meet_the_hero_seraphina",
    PostImg: Seraphina,
    link: "https://guildofheroes.medium.com/seraphina-140d4ef523e7",
    title: "Meet the Hero: Seraphina",
    desc: `In the quietest whispers of twilight, Seraphina, the Serpent Assassin of “Guild of Heroes,” emerges. She is the unseen danger, the silent blade in the dark, whose very presence is as enigmatic as it is lethal. With movements that mirror the serpent’s sinuous grace, she strikes with a cold precision known only to the shadows.`,
    createdAt: "Jan 11, 2024",
    content: SeraphinaContent,
  },
  {
    id: "meet_the_hero_flora",
    PostImg: Flora,
    link: "https://guildofheroes.medium.com/flora-31ad32c0f956",
    title: "Meet the Hero: Flora",
    desc: `In every whisper of the wind and rustle of leaves, Flora, the enchanting dryad of “Guild of Heroes,” moves with the grace of the living forest. She is the guardian of growth, the weaver of woodlands, channeling the ancient power of nature to both nurture and neutralize.`,
    createdAt: "Jan 11, 2024",
    content: FloraContent,
  },
  {
    id: "meet_the_hero_felsight",
    PostImg: Cyclovis,
    link: "https://guildofheroes.medium.com/cyclovis-cd663cf147b8",
    title: "Meet the Hero: Felsight",
    desc: `From the secluded peaks where ancient echoes resonate, Felsight the Unblinking takes his solemn stand in “Guild of Heroes.” This solitary cyclops, hewn from the very heart of the ancient mountains, is a sentinel of stone and strength. With an eye that pierces through chaos and a focus sharp as the edge of dawn, he surveys the battlefield with an anticipation that belies his imposing form.​`,
    createdAt: "Jan 11, 2024",
    content: FelsightContent,
  },
  {
    id: "meet_the_hero_ravos",
    PostImg: Ravencrow,
    link: "https://guildofheroes.medium.com/ravencrow-b0dd53a058cb",
    title: "Meet the Hero: Ravos",
    desc: `From the shadowed realms of Arcanterra, emerges Ravencrow, a warrior bound to the spirits of the air. With wings as dark as the raven and eyes glowing with the foresight of the crow, he stands as a sentinel between the worlds. Ravencrow wields his spectral axe with otherworldly precision, his strikes a blend of the ethereal and the lethal, as he dominates the skies and battlefields alike.​`,
    createdAt: "Jan 11, 2024",
    content: RavosContent,
  },
  {
    id: "meet_the_hero_ferno",
    PostImg: Flamelord,
    link: "https://guildofheroes.medium.com/meet-the-hero-flame-lord-daaac6f01031",
    title: "Meet the Hero: Ferno",
    desc: `From the molten depths of Guild of Heroes comes Ferno, a titanic force of nature whose fiery heart burns with the intensity of a thousand suns. This colossal elemental entity wields the raw power of fire and earth, scorching enemies with his fiery roar and leaving devastation in his wake with every thunderous step.​`,
    createdAt: "Jan 11, 2024",
    content: FernoContent,
  },
  {
    id: "meet_the_hero_silverfang",
    PostImg: Silverclaw,
    link: "https://guildofheroes.medium.com/meet-the-hero-silverclaw-09e738b66fdd",
    title: "Meet the Hero: Silverfang",
    desc: `Silverfang strikes not just with his limbs but with fear itself. As a beast woven from the nightmares of his enemies, his presence on the battlefield is as chilling as it is deadly. His fearsome howl is the prelude to a symphony of destruction, as his silver-coated claws catch the moonlight before they strike.​`,
    createdAt: "Jan 11, 2024",
    content: SilverfangContent,
  },
  {
    id: "meet_the_hero_zephyrion",
    PostImg: Voltclaw,
    link: "https://guildofheroes.medium.com/meet-the-hero-voltclaw-4118cf736204",
    title: "Meet the Hero: Zephyrion",
    desc: `Amidst the heroes of Guild of Heroes, Zephyrion stands as a beacon of raw power and electrifying presence. His tragic past fuels his relentless pursuit for justice, channeling the very force of electricity to stun and subdue his adversaries.​`,
    createdAt: "Jan 11, 2024",
    content: ZephyrionContent,
  },
  {
    id: "guild_of_heroes_marketplace_explained",
    PostImg: MarketplaceNews,
    link: "https://guildofheroes.medium.com/guild-of-heroes-marketplace-explained-863ac92e11f1",
    title: "Guild of Heroes Marketplace Explained",
    desc: `The in-game marketplace of Guild of Heroes offers a dynamic and enriching aspect to the gaming experience. Serving as a central feature, it provides players an avenue to augment their gameplay through a diverse range of upgrades and customization options.​`,
    createdAt: "Jan 11, 2024",
    content: MarketplaceContent,
  },
  {
    id: "meet_the_hero_noble",
    PostImg: NewEight,
    link: "https://guildofheroes.medium.com/paladis-40780fb47f88",
    title: "Meet the Hero: Noble",
    desc: `In the verdant fields and over the storied cobblestones of Arcanterra strides Noble, the weathered knight whose very name evokes tales of valor and perseverance. With the wisdom of countless battles etched into his being, Noble wields his axe not merely as a weapon, but as an extension of a lifetime dedicated to the mastery of martial prowess.`,
    createdAt: "Jan 11, 2024",
    content: NobleContent,
  },
  {
    id: "meet_the_hero_drysia",
    PostImg: NewFour,
    link: "https://guildofheroes.medium.com/drysia-e9dc501cce19",
    title: "Meet the Hero: Drysia",
    desc: `In the heart of the shadowed groves of Arcanterra, Drysia stands as the chieftain of whispers and dusk. Clad in the might of the night and wielding twin obsidian axes, she commands the battlefield with the authority of darkness itself. Her presence is a foreboding omen, her power an echo of the void that swallows light.`,
    createdAt: "Jan 11, 2024",
    content: DrysiaContent,
  },
  {
    id: "guild_of_heroes_burnstream_mechanics",
    PostImg: New11,
    link: "https://guildofheroes.medium.com/guild-of-heroes-burnstream-mechanics-00c4c9e6b87b",
    title: "Guild of Heroes Burnstream Mechanics",
    desc: `In Guild of Heroes, the burn mechanism operates through a dual-pathed approach that ensures both the game’s economy and its players benefit from the process.​`,
    createdAt: "Jan 11, 2024",
    content: BurnMechanismContent,
  },
  {
    id: "meet_the_hero_fernix",
    PostImg: NewThree,
    link: "https://guildofheroes.medium.com/draconis-223657828851",
    title: "Meet the Hero: Fernix",
    desc: `From the ancient roosts where legends take flight, Fernix emerges, his very essence a testament to the might and majesty of dragons of yore. Clad in scales that shimmer with the wisdom of aeons, he stands as a guardian, a mage whose power resonates with the heartbeats of dragons long passed.`,
    createdAt: "Jan 11, 2024",
    content: FernixContent,
  },
];

export const Faqs = [
  {
    question: "Is Guild of Heroes free to play?",
    answer:
      "Yes, Guild of Heroes is free to play, with optional in-game purchases available for players who wish to enhance their gaming experience.",
  },
  {
    question: "On which platforms will Guild of Heroes be available?",
    answer:
      "Guild of Heroes will be available on both PC and mobile platforms, ensuring accessibility for players with different gaming preferences.",
  },
  {
    question: "How does the in-game economy work in Guild of Heroes?",
    answer:
      "The in-game economy is built around GOH Coins, which is used for in-game upgrades and purchases to enhance your heroes, and GOH Tokens, which can be obtained through staking. This economy allows for dynamic trading and strategic advancement within the game.",
  },
  {
    question: "How is GOH Coins acquired and used in Guild of Heroes?",
    answer:
      "GOH Coins is acquired through active gameplay and victories. It can be used to purchase upgrades, thereby increasing your chances of success in future games. Winners can keep a percentage of their earned GOH Coins, which can be sold to other players or used for staking to earn GOH Tokens.",
  },
  {
    question: "What is the GOH Token, and what are its benefits?",
    answer:
      "The GOH Token is Guild of Heroes' native cryptocurrency. It's primarily used in the in-game marketplace for transactions and staking, offering additional benefits and privileges within the game's ecosystem.",
  },
  {
    question: "How does staking work in Guild of Heroes?",
    answer:
      "Staking in Guild of Heroes allows players to earn GOH Tokens by locking in their GOH Coins. This rewards players based on the amount and duration of their stake, supporting the game's economy by stabilizing the currency.",
  },
  {
    question:
      "How does the GOH Token burn mechanism work, especially in relation to purchasing skins?",
    answer:
      "The GOH Token burn mechanism is integral to the game's economy. When skins or items are purchased with GOH Tokens, 50% of the tokens spent are burned, reducing the supply and creating deflationary pressure. When purchases are made with fiat currency, a corresponding amount of GOH Tokens is bought from the open market and burned, mirroring the deflationary effect and ensuring value is returned to the token ecosystem.",
  },
  {
    question: "Can I earn rewards by playing Guild of Heroes?",
    answer:
      "Yes, players earn GOH Coins through gameplay. This gold can be used for in-game transactions or staked to earn GOH Tokens, which are used for exclusive content access and other privileges.",
  },
  {
    question: "When will Guild of Heroes be released?",
    answer:
      "The beta version of Guild of Heroes is scheduled for release in Q1 2024, with the official launch set for Q1 2025.",
  },
  {
    question:
      "Will there be updates and new content after the launch of Guild of Heroes?",
    answer:
      "Yes, Guild of Heroes will continue to evolve post-launch with regular updates, new content, and community events to keep the game fresh and engaging.",
  },

  {
    question: "What types of heroes are available in Guild of Heroes?",
    answer:
      "Guild of Heroes features a diverse cast of heroes, each with unique abilities and playstyles, catering to various strategic preferences.",
  },
  {
    question: "Are there any special items or skins in Guild of Heroes?",
    answer:
      "Yes, players can acquire a variety of items and skins through gameplay achievements, purchases with GOH Coins, or transactions in the marketplace using GOH Tokens.",
  },
  {
    question: "Can I trade items with other players in Guild of Heroes?",
    answer:
      "Yes, the game features a secondary marketplace where players can trade rare skins and items, fostering a dynamic, player-driven economy.",
  },
  {
    question:
      "What is the significance of the Avalanche blockchain in Guild of Heroes?",
    answer:
      "The Avalanche blockchain underpins the game's transactions and asset ownership, providing a fast, secure, and eco-friendly platform for a decentralized gaming experience.",
  },
  {
    question: "How can I participate in Guild of Heroes tournaments?",
    answer:
      "Players can enter tournaments by paying an entry fee. These tournaments provide competitive play and community engagement, with additional rewards for skilled performance.",
  },
  {
    question: "What are Battle Passes in Guild of Heroes?",
    answer:
      "Battle Passes unlock a series of progressive rewards as players complete objectives or level up within a game season. They provide exclusive content and additional gameplay incentives.",
  },
  {
    question:
      "How does the credit card purchase system work within Guild of Heroes?",
    answer:
      "When players make purchases with credit cards, part of the transaction value is used to purchase and burn GOH Tokens, while the other half contributes to the game's development.",
  },
  {
    question: "How is the revenue generated from GOH Token sales used?",
    answer:
      "Revenue from GOH Token sales is split between funding game development and burning a portion to reduce supply and increase value.",
  },
  {
    question: "What strategies should I focus on in Guild of Heroes?",
    answer:
      "Success in Guild of Heroes hinges on teamwork, strategic planning, and mastering your chosen heroes. Coordination with your team and adapting to the evolving gameplay are essential strategies.",
  },
  {
    question: "How does the end-of-game reward system work?",
    answer:
      "After each match, winners keep a portion of their GOH Coins, which can be used for marketplace discounts or saved for future transactions, rewarding consistent play and high performance.",
  },
];

export const contactInfo = [
  {
    title: "General Inquiries",
    description:
      "For general questions or feedback about Guild of Heroes, feel free to drop us a message.",
    email: "contact@guildofheroes.com",
  },
  {
    title: "Player Support",
    description:
      "Need help with your game experience? Our support team is ready to assist you.",
    email: "support@guildofheroes.com",
  },
  {
    title: "Business & Partnerships",
    description:
      "Interested in collaborating or partnering with us? Let's discuss potential opportunities.",
    email: "partnerships@guildofheroes.com",
  },
  {
    title: "Investor Relations",
    description:
      "For investment-related queries or information, reach out to our dedicated team.",
    email: "investors@guildofheroes.com",
  },
  {
    title: "Direct Contact",
    description:
      "Prefer a direct conversation? Connect with a team member on Telegram for immediate assistance.",
    text: "@Rfassbender",
  },
];

export const footerMenu = [
  {
    title: "Contact",
    emailList: [
      {
        image: mail,
        link: "mailto:contact@guildofheroes.com",
        text: "contact@guildofheroes.com",
      },
      {
        image: mail,
        link: "mailto:support@guildofheroes.com",
        text: "support@guildofheroes.com",
      },
      {
        image: mail,
        link: "mailto:partnerships@guildofheroes.com",
        text: "partnerships@guildofheroes.com",
      },
      {
        image: mail,
        link: "mailto:investors@guildofheroes.com",
        text: "investors@guildofheroes.com",
      },
    ],
  },
  {
    title: "Links",
    menuList: [
      {
        text: "Home",
        link: "/",
      },
      {
        text: "About Us",
        link: "/about-us",
      },
      {
        text: "Whitepaper",
        link: "https://guildofheroes.gitbook.io",
        target: true,
      },
      {
        text: "Heroes",
        link: "/heroes",
      },
      {
        text: "News",
        link: "/news",
      },
      // {
      //   text: "Contact",
      //   link: "/contact-us",
      // },
      {
        text: "Branding",
        link: "/Branding",
      },
    ],
  },
  {
    title: "Legal",
    legalList: [
      {
        text: "Privacy Policy",
        link: "https://www.nxentertainment.com/privacy-policy",
      },
      {
        text: "Terms of Service",
        link: "https://www.nxentertainment.com/terms-of-service",
      },
      // {
      //   text: "Cookie Policy",
      //   link: "/CookiePolicy",
      // },
    ],
  },
  {
    title: "Follow Us",
    soicalList: [
      {
        image: telegram,
        link: "https://t.me/GOHofficial",
      },
      {
        image: tw,
        link: "https://twitter.com/guildofheroes_",
      },
      {
        image: yt,
        link: "https://www.youtube.com/@GuildOfHeroes_",
      },
      {
        image: medium,
        link: "https://guildofheroes.medium.com/",
      },
      {
        image: discord,
        link: "https://discord.gg/qr2fUaf2p7",
      },
    ],
  },
];
export const privacycontent = [
  {
    title: "Introduction:",
    para: [
      {
        text: 'Welcome to Guild of Heroes. Your privacy is constant to us. This Privacy Policy outlines how we collect, use, protect, and handle your personal information in relation to our website, mobile games, and other related services (collectively, the "Services").',
      },
    ],
  },
  {
    title: "Definitions and Scope:",
    para: [
      {
        text: "This Privacy Policy applies to all services offered by Guild of Heroes. 'Personal Information' refers to any information relating to an identified or identifiable individual.",
      },
    ],
  },
  {
    title: "Information Collection::",
    para: [
      {
        text: "We collect the following types of personal information:",
      },
      {
        strongText: "Email Address:",
        text: " For communication, updates, and marketing",
      },
      {
        strongText: "Date of Birth:",
        text: "To verify age and for demographic analysis.",
      },
      {
        strongText: "Name:",
        text: "For account setup and personalization.",
      },
    ],
  },
  {
    title: "How We Use Your Information:",
    para: [
      {
        text: "We use the information we collect for various purposes, including:",
      },
    ],

    listing: [
      "To provide and maintain our Services.",
      "To notify you about changes to our Services.",
      " To allow you to participate in interactive features of our Services when you choose to do so.",
      "To provide customer support.",
      "To gather analysis or valuable information so that we can improve our Services.",
      "To monitor the usage of our Services.",
      "To detect, prevent and address technical issues.",
      "For marketing and promotional activities.",
    ],
  },
  {
    title: "High-Level Security Measures:",
    para: [
      {
        text: "We implement highly sophisticated encryption technologies and robust security measures to protect your data. These measures, designed to secure your personal information, prevent unauthorized access, disclosure, alteration, and destruction.",
      },
    ],
  },
  {
    title: "International Data Transfers:",
    para: [
      {
        text: "We handle international data transfers with a high level of care and security, ensuring compliance with all applicable laws and regulations. We ensure that data transferred across borders is afforded the same level of security and protection as within your home country.",
      },
    ],
  },
  {
    title: "Data Breach Notification:",
    para: [
      {
        text: "In the event of a data breach, we will promptly notify affected individuals and provide guidance on protective measures. We commit to taking all necessary steps to mitigate any harm and prevent future breaches.",
      },
    ],
  },
  {
    title: "Your Rights:",
    para: [
      {
        text: "You have the right to access, correct, or delete your personal information, object to or restrict processing, and request data portability. For any such requests, please contact us at",
        link: "mailto:contact@guildofheroes.com",
        linkText: "contact@guildofheroes.com.",
      },
    ],
  },
  {
    title: `Cookies and Tracking Technologies:`,
    para: [
      {
        text: `We use cookies and similar technologies to enhance user experience, analyze trends, and deliver personalized content. You can manage your cookie preferences through your browser settings.`,
      },
    ],
  },
  {
    title: "Changes to This Policy",
    para: [
      {
        text: "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the 'effective date' at the top.",
      },
    ],
  },
  {
    title: "Contact Us:",
    para: [
      {
        text: "For any questions about this Privacy Policy or our privacy practices, please contact us at",
        link: "mailto:contact@guildofheroes.com",
        linkText: "contact@guildofheroes.com.",
      },
    ],
  },
];
export const termsofServics = [
  {
    title: "Introduction:",
    para: [
      {
        text: 'Welcome to Guild of Heroes. These Terms of Service ("Terms") govern your use of our website, mobile games, and other related services (collectively, the "Services"). By accessing or using the Services, you agree to be bound by these Terms.',
      },
    ],
  },
  {
    title: "Acceptance of Terms:",
    para: [
      {
        text: "By using our Services, you confirm that you accept these Terms and that you agree to comply with them. If you do not agree to these Terms, you must not use our Services.",
      },
    ],
  },
  {
    title: "Changes to Terms:",
    para: [
      {
        text: "We reserve the right to update or modify these Terms at any time. Your continued use of the Services after any such changes constitutes your acceptance of the new Terms.",
      },
    ],
  },
  {
    title: "Use of Services:",
    para: [
      {
        text: "Our Services are intended for users who are at least the age of majority in their jurisdiction. You may not use our Services if you do not meet this requirement.",
      },
    ],
  },
  {
    title: "User Accounts:",
    para: [
      {
        text: "You may be required to register an account to access certain features of the Services. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
      },
    ],
  },
  {
    title: "Privacy Policy:",
    para: [
      {
        text: "Our Privacy Policy, which details how we collect and use your personal information, is available [Insert Link to Privacy Policy]. By using our Services, you agree to the collection and use of information in accordance with our Privacy Policy.",
      },
    ],
  },
  {
    title: "User Conduct:",
    para: [
      {
        text: "You agree not to engage in any of the following prohibited activities:",
      },
    ],
    listing: [
      "Illegal activities or encouraging criminal behavior.",
      "Transmitting harmful or disruptive code.",
      " Infringing upon the rights of others, including intellectual property rights.",
      " Harassment, bullying, or promoting violence or hatred.",
    ],
  },
  {
    title: "Content You Provide:",
    para: [
      {
        text: "You are responsible for the content you provide while using the Services, including its legality, reliability, and appropriateness. You retain all rights in, and are solely responsible for, the content you post.",
      },
    ],
  },
  {
    title: `Intellectual Property Rights:`,
    para: [
      {
        text: `All rights, title, and interest in and to the Services and its content, features, and functionality (including all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are and will remain the exclusive property of Guild of Heroes and its licensors.`,
      },
    ],
  },
  {
    title: "Termination:",
    para: [
      {
        text: "We may terminate or suspend your access to the Services immediately, without prior notice or liability, for any breach of these Terms.",
      },
    ],
  },
  {
    title: "Disclaimers:",
    para: [
      {
        text: "The Services are provided 'as is' and 'as available,' without any warranties of any kind, either express or implied. Guild of Heroes does not guarantee the accuracy, completeness, or usefulness of the Services.",
      },
    ],
  },
  {
    title: "Limitation of Liability:",
    para: [
      {
        text: "Guild of Heroes, its affiliates, and their licensors will not be liable for any damages of any kind arising from the use of the Services or from any information, content, materials, products, or other services included on or otherwise made available to you through the Services.",
      },
    ],
  },
  {
    title: "Governing Law:",
    para: [
      {
        text: "These Terms and any disputes arising from or relating to the interpretation, violation, enforceability, or validity of these Terms, including the determination of the scope or applicability of this agreement to arbitrate, shall be governed by and construed in accordance with the laws of [Jurisdiction].",
      },
    ],
  },
  {
    title: "Dispute Resolution:",
    para: [
      {
        text: "Any disputes arising out of or in connection with these Terms will be resolved through binding arbitration in accordance with the rules of [Arbitration Association] in the jurisdiction of [Location].",
      },
    ],
  },
  {
    title: "Contact Information:",
    para: [
      {
        text: "If you have any questions about these Terms, please contact us at",
        link: "mailto:contact@guildofheroes.com",
        linkText: "contact@guildofheroes.com.",
      },
    ],
  },
];
export const CookiePolicys = [
  {
    title: "Introduction:",
    para: [
      {
        text: 'Welcome to Guild of Heroes. This Cookie Policy provides detailed information about how and why we use cookies and similar technologies on our website, in our mobile games, and across our other related services (collectively, the "Services"). By using our Services, you consent to the use of cookies as described in this policy.',
      },
    ],
  },
  {
    title: "Understanding Cookies:",
    para: [
      {
        text: "Cookies are small text files stored on your device (computer, tablet, mobile phone) when you visit certain web pages. They are used to remember your preferences, enhance user experience, and tailor the adverts you see to those that are most relevant to you.",
      },
    ],
  },
  {
    title: "Our Use of Cookies:",
    para: [
      {
        text: "We use cookies for several reasons:",
      },
    ],
    listing: [
      "Essential Operations: Cookies are vital for the basic functioning of our Services, including security, network management, and accessibility.",
      "Performance and Analytics: Cookies help us analyze how our Services are accessed, used, or performing. We use this information to maintain, operate, and continually improve our Services.",
      " Functionality: These cookies remember your preferences and enable personalized features.",
      "Advertising: We use cookies to deliver targeted advertising and track the efficiency of our advertising campaigns.",
    ],
  },
  {
    title: "Types of Cookies Used:",
    listing: [
      "Strictly Necessary Cookies: These are cookies that are required for the operation of our Services.",
      "Analytical/Performance Cookies: They allow us to recognize and count the number of visitors and see how visitors move around our Services.",
      "Functionality Cookies: Used to recognize you when you return to our Services.",
      "Targeting Cookies: Record your visit to our Services, the pages you have visited, and the links you have followed.",
    ],
  },
  {
    title: "Third-Party Cookies:",
    para: [
      {
        text: "In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.",
      },
    ],
  },
  {
    title: "Managing Cookies:",
    para: [
      {
        text: "You can manage the cookies preferences on your device through your browser settings, allowing you to refuse some or all cookies, except for strictly necessary cookies. Be aware that blocking cookies may impact your user experience on our Services.",
      },
    ],
  },
  {
    title: "Cookies and Personal Data:",
    para: [
      {
        text: "Cookies may collect personal information, which is handled in accordance with our Privacy Policy. We ensure that all data collected by cookies is processed and stored securely.",
      },
    ],
  },
  {
    title: "Content You Provide:",
    para: [
      {
        text: "You are responsible for the content you provide while using the Services, including its legality, reliability, and appropriateness. You retain all rights in, and are solely responsible for, the content you post.",
      },
    ],
  },
  {
    title: `Consent:`,
    para: [
      {
        text: `By using our Services, you consent to the use of cookies as set out in this policy. We will ask for your consent to our use of cookies when you first visit our Services.`,
      },
    ],
  },
  {
    title: "Changes to This Policy::",
    para: [
      {
        text: "We may update this Cookie Policy to reflect changes to our cookies practices or for other operational, legal, or regulatory reasons.",
      },
    ],
  },
  {
    title: "Contact Information:",
    para: [
      {
        text: "For further information about our Cookie Policy or if you have any questions or concerns, please contact us at",
        link: "mailto:contact@guildofheroes.com",
        linkText: "contact@guildofheroes.com.",
      },
    ],
  },
];

export const DashboardMenu = [
  {
    icon: HomeMenu,
    text: "Home",
    link: "/dashboard",
  },
  {
    icon: DailyMenu,
    text: "Daily Tasks",
    link: "/daily-task",
  },
  {
    icon: WeeklyMenu,
    text: "Tasks",
    link: "/tasks",
  },
  {
    icon: ActiveMenu,
    text: "Active Tasks",
    link: "/active-task",
    count: 3,
  },
  {
    icon: CompleteMenu,
    text: "Completed Tasks",
    link: "/completed-tasks",
  },
  {
    icon: CombatMenu,
    text: "Combat Earnings",
    link: "/combat-earnings",
  },
  {
    icon: HighMenu,
    text: "Highscores",
    link: "/highscores",
  },
  {
    icon: DLogoIcon,
    text: "Airdrop",
    link: "/airdrop",
  },
];

export const WeeklyTaskList = [
  {
    icon: DHeroIcon,
    title: "Choose your hero",
    count: "4,000",
    color: "#FFF",
    linkText: "Choose your hero",
    desc: "After selecting your character, it will take our moderators one hour to process your follow, allowing you to claim your prize.",
    isHero: true,
    type: "Choose-your-hero",
  },
  {
    icon: YouTubIcon,
    title: "Watch the youtube video",
    count: "4,000",
    color: "#FF0000",
    linkText: "Start the task",
    desc: "After you watch our latest videos and subscribe to our channel, our moderators will take one hour to check your actions, after which you can receive your prize.",
    btnLink: "https://www.youtube.com/watch?v=6DaPKO2bUfI&t",
    target: true,
    type: "Watch-the-youtube-video",
  },
  {
    icon: TwitterXIcon,
    title: "Follow us on X",
    count: "4,000",
    color: "#000",
    linkText: "Start the task",
    desc: "After you follow our X page, it will take our moderators one hour to process your follow, allowing you to claim your prize.",
    btnLink: "https://x.com/GuildOfHeroes_",
    status: "Active",
    type: "Follow-us-on-X",
  },
  {
    icon: InstagramIcon,
    title: "Follow us on instagram",
    count: "4,000",
    color: "#F00073",
    linkText: "Start the task",
    desc: "After following us on instagram, it will take our moderators one hour to process your follow, allowing you to claim your prize.",
    btnLink: "https://www.instagram.com/guildofheroes_/",
    target: true,
    type: "Follow-us-on-instagram",
  },
  {
    icon: FacebookIcon,
    title: "Follow us on Facebook",
    count: "4,000",
    color: "#1877F2",
    linkText: "Start the task",
    desc: "After following us on Facebook, wait 1 hour for a moderation check to claim the prize",
    btnLink: "https://www.facebook.com/profile.php?id=61564000517173",
    status: "Active",
    type: "Follow-us-on-Facebook",
  },
  {
    icon: TelegramIcon,
    title: "Join our Telegram channel",
    count: "4,000",
    color: "#0088CC",
    linkText: "Start the task",
    desc: "After joining our Telegram channel, it will take our moderators one hour to process your join, allowing you to claim your prize.",
    btnLink: "https://t.me/guildofheroes_Bot",
    target: true,
    type: "Join-to-our-Telegram-channel",
  },
  {
    id: 7,
    icon: TelegramIcon,
    title: "Join our Announcement Channel",
    count: "4,000",
    color: "#0088CC",
    linkText: "Start the task",
    desc: "After joining our Announcement Channel, it will take our moderators one hour to process your join, allowing you to claim your prize.",
    status: "Active",
    btnLink: "http://t.me/gohAnnouncements",
    target: true,
    type: "Join-Announcement-channel",
  },
  {
    id: 8,
    icon: TwitterXIcon,
    title: "Like and Share Our latest post",
    count: "4,000",
    color: "#000",
    linkText: "Start the task",
    desc: "After you like and share our post on X, wait 1 hour for a moderation check to claim the prize",
    btnLink: "https://x.com/GuildOfHeroes_/status/1821402935684702480",
    type: "Like-and-share-our-post",
  },
];

export const DailyTaskList = [
  {
    id: 1,
    logo: DDailyTaskLogo,
    title: "day 1",
    coins: 100,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 2,
    logo: DDailyTaskLogo,
    title: "day 2",
    coins: 1000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 3,
    logo: DDailyTaskLogo,
    title: "day 3",
    coins: 2000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 4,
    logo: DDailyTaskLogo,
    title: "day 4",
    coins: 5000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 5,
    logo: DDailyTaskLogo,
    title: "day 5",
    coins: 15000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 6,
    logo: DDailyTaskLogo,
    title: "day 6",
    coins: 25000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 7,
    logo: DDailyTaskLogo,
    title: "day 7",
    coins: 100000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 8,
    logo: DDailyTaskLogo,
    title: "day 8",
    coins: 500000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 9,
    logo: DDailyTaskLogo,
    title: "day 9",
    coins: 1000000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
  {
    id: 10,
    logo: DDailyTaskLogo,
    title: "day 10",
    coins: 5000000,
    color: "#E4B81D",
    linkText: "claim",
    status: "active",
  },
];

export const HighscoresData = [
  {
    id: 1,
    name: "ArQuel",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 2,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 3,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 4,
    name: "ImTu",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 5,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 6,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 7,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 8,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 9,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 10,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },

  {
    id: 11,
    name: "ArQuel",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },

  {
    id: 12,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 13,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 14,
    name: "ImTu",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 15,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 16,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 17,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 18,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 19,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 20,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },

  {
    id: 21,
    name: "ArQuel",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 22,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 23,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 24,
    name: "ImTu",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 25,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 26,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 27,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 28,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 29,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 30,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },

  {
    id: 31,
    name: "ArQuel",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 32,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 33,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 34,
    name: "ImTu",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 35,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 36,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 37,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 38,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 39,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 40,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },

  {
    id: 41,
    name: "ArQuel",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 42,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 43,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 44,
    name: "ImTu",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 45,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 46,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 47,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 48,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 49,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 50,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },

  {
    id: 51,
    name: "ArQuel",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 52,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 53,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 54,
    name: "ImTu",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 55,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 56,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 57,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 58,
    name: "Termin",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 59,
    name: "Alainey",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 60,
    name: "RealT",
    score: "20",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
];

export const scoresColumns = [
  { Header: "Rank", accessor: "rank" },
  {
    Header: "Username",
    accessor: "displayName",
    cell: ({ row }) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={row?.avatarUrl}
            alt={row?.userName}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://via.placeholder.com/40";
            }}
          />
          {row?.displayName || row?.userName}
        </div>
      );
    },
  },
  {
    Header: "Coins Earned",
    accessor: "gohCoins",
    cell: ({ row }) => formatNumberWithCommasForHero(row?.gohCoins),
  },
];

export const eraningDataList = [
  {
    title: "Per Hourly Playtime",
    tooltip: `Earn +125,000 coins for every hour you spend actively playing the game.`,
    hours: "0",
    count: "+125,000",
    desc: "Tracks the total duration you've spent in-game, emphasizing that every hour counts. This metric is key in assessing your overall engagement and activity levels",
  },
  {
    title: "Per Victory",
    tooltip: `Earn +60,000 coins for each game you win, reflecting the rewards of your strategic prowess and success in matches.`,
    hours: "0",
    count: "+60,000",
    desc: "Records the amount of gold earned for each game you win. It reflects the rewards of your strategic prowess and success in matches",
  },
  {
    title: "Per Defeat",
    tooltip: `Earn +15,000 coins for each game lost, rewarding you even in defeat and softening the blow.`,
    hours: "0",
    count: "+15,000",
    desc: "Shows the gold earned from games lost. Even in defeat, you accumulate rewards, helping soften the blow",
  },
  {
    title: "Per Player Elimination",
    tooltip: `Earn +5,000 coins for every player elimination, rewarding your combat skills against other players.`,
    hours: "0",
    count: "+5,000",
    desc: "Monitors gold earned for every opponent player you defeat. Shows the profitability of your combat skills against other players",
  },
  {
    title: "Per Tower Destruction",
    tooltip: `Earn +20,000 coins for every enemy tower you destroy, rewarding your strategic gameplay.`,
    hours: "0",
    count: "+20,000",
    desc: "Captures the gold for each tower you destroy. This is a key strategic achievement in gameplay, rewarding your capabilities",
  },
  {
    title: "Per NPC Kill",
    tooltip: `Earn +25 coins for every NPC kill you make, representing rewards from defeating NPCs.`,
    hours: "0",
    count: "+25",
    desc: "Details the gold earned for each non-player character (NPC) kill. It quantifies the rewards from engagements with adversaries",
  },
];

export const airdropData = [
  {
    id: 1,
    icon: DWallet,
    title: "Connect your wallet",
    color: "#fff",
    linkText: "connect",
    decs: "Connect your cryptocurrency wallet. If you don't have one, create one in your Telegram account",
  },
  {
    id: 2,
    icon: TelegramIcon,
    title: "Connect with Telegram Mini App",
    color: "#0088CC",
    linkText: "Go to Telegram App",
    btnLink: "https://t.me/guildofheroes_Bot",
    decs: "Connect your cryptocurrency wallet. If you don't have one, create one in your Telegram account",
    target: true,
  },
];

export const rewardFeedColumns = [
  {
    Header: "User",
    accessor: "userName",
    cell: ({ row }) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={row.playerAvatar || "https://via.placeholder.com/40"}
            alt={row.name}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://via.placeholder.com/40";
            }}
          />
          <span>{row.userName}</span>
        </div>
      );
    },
  },
  {
    Header: "Task Completed",
    accessor: "eventType",
    cell: ({ row }) => {
      return <span>{formatEventType(row?.eventType)}</span>;
    },
  },
  {
    Header: "GOH coins",
    accessor: "rewardEarned",
    cell: ({ row }) => currencyFormat(row?.rewardEarned),
  },
];

export const RewardFeedData = [
  {
    id: 1,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 2,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 3,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 4,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 5,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 6,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 7,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 8,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 9,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 10,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
  {
    id: 11,
    name: "ArQuel",
    completeTask: "Killed an enemy",
    coin: "+100",
    image: "https://oodp.ca/media/tutor-8.jpg",
    detailList: [
      // {
      //   key: "Level",
      //   value: "25",
      // },
      {
        key: "Rank",
        value: "1",
      },
      {
        key: "Kills",
        value: "10",
      },
      {
        key: "Deaths",
        value: "3",
      },
      {
        key: "Games Won",
        value: "7",
      },
      {
        key: "Games Lost",
        value: "4",
      },
      {
        key: "Towers Destroyed",
        value: "5",
      },
      {
        key: "NPC Kills",
        value: "20",
      },
      {
        key: "Total Gold",
        value: "20",
      },

      {
        key: "Referrals",
        value: "3",
      },
    ],
  },
];
