import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../../services/user";
import { disconnectSocket } from "../../services/socket";

const initialState = {
  token: localStorage.getItem("token"),
  isLoading: true,
  user: null | undefined,
  playFabInfo: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.clear();
      disconnectSocket();
    },
    setUserCoins: (state, action) => {
      state.user = {
        ...state.user,
        gohCoins: state.user.gohCoins + action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.getUserById.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(
        userApi.endpoints.getUserById.matchFulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          state.user = payload?.user;
          state.playFabInfo = payload?.playFabData?.data;
        }
      );
  },
});

export default authSlice;
export const { setToken, logout, setUserCoins } = authSlice.actions;
