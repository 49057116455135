import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import "./style.css";
import DTaskCard from "../../../Components/DtaskCard";
import TaskModels from "./taskModels";
import DloginModal from "../../../Components/DloginModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DailyTaskModels from "../dailyTaskModels";
import {
  useActivateSocialTaskMutation,
  useGetSocialTaskMutation,
  useGetUserSocialTaskMutation,
} from "../../../services/socialTask";

const DtaskScreen = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isStepsModal, setIsStepsModal] = useState(false);
  const [isCurrentModelName, setIsCurrentModelName] = useState("");
  const [isDataId, setIsDataId] = useState();
  const { user } = useSelector((state) => state.auth);
  const { socialTasks } = useSelector((state) => state.socialTask);

  const [getAllTasks, { isLoading }] = useGetSocialTaskMutation();
  const [getUserTasks, { isLoading: isTaskLoading }] =
    useGetUserSocialTaskMutation();
  const [activateUserTask] = useActivateSocialTaskMutation();
  const { heroImage } = useSelector((state) => state.task);

  const fetchUserTask = useCallback(async () => {
    try {
      user ? getUserTasks(user._id) : getAllTasks();
    } catch (err) {
      toast.error(err?.message);
    }
  }, [getAllTasks, getUserTasks, user]);

  const activeUserTask = async (taskId) => {
    const body = {
      user: user?._id,
      socialTask: taskId,
    };
    try {
      const response = await activateUserTask(body).unwrap();
      if (response.status === true) {
        await fetchUserTask();
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  useEffect(() => {
    fetchUserTask();
  }, [fetchUserTask]);

  const onJoin = (selectedItem) => {
    activeUserTask(selectedItem?._id);
    setIsModelOpen(false);
    window.open(selectedItem?.buttonLink, "_blank");
  };
  const onCheck = (selectedItem) => {
    console.log("selectedItem", selectedItem);
  };

  const filteredTasks = socialTasks || [];

  console.log("filteredTasks", filteredTasks);

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <h2>tasks</h2>
          <Button
            variant="secondary-contained"
            text="how it works"
            onClick={() => setIsStepsModal(true)}
          />
        </div>

        {filteredTasks.length === 0 && !isLoading ? (
          <div>You don't have any task yet</div>
        ) : null}

        {(filteredTasks.length === 0 && isLoading) ||
        (filteredTasks.length === 0 && isTaskLoading) ? (
          <div className="load-full">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="d-task-list">
            {filteredTasks?.map((item, index) => (
              <DTaskCard
                data={item}
                key={index}
                isHeroSelected={item?.title === "Choose your hero" && heroImage}
                style={{
                  backgroundImage:
                    item?.title === "Choose your hero" && heroImage
                      ? `url(${heroImage})`
                      : "",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                onClick={() => {
                  if (item.isHero) {
                    setIsModelOpen(true);
                    if (heroImage) {
                      setIsCurrentModelName("stepsthree");
                    } else {
                      setIsCurrentModelName("steptwo");
                    }
                    console.log("hero modal is open", item);
                    setIsDataId(item._id);
                  } else {
                    console.log("mode 2 is open", item);
                    setIsCurrentModelName("stepone");
                    setIsModelOpen(true);
                    setIsDataId(item._id);
                  }
                }}
              />
            ))}
          </div>
        )}
      </div>

      <DailyTaskModels
        isActive={isStepsModal}
        onClose={() => setIsStepsModal(false)}
        activeModel="stepone"
      />

      {user ? (
        <TaskModels
          onClose={() => {
            setIsModelOpen(false);
            setIsDataId();
          }}
          isActive={isModelOpen}
          activeModelName={isCurrentModelName}
          id={isDataId}
          onJoin={onJoin}
          onCheck={onCheck}
          onConfirm={() => {
            fetchUserTask();
            setIsModelOpen(false);
          }}
        />
      ) : (
        <DloginModal
          onClose={() => {
            setIsModelOpen(false);
          }}
          isActive={isModelOpen}
        />
      )}
    </>
  );
};

export default DtaskScreen;
